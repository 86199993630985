.upload-list-inline .ant-upload-list-item {
     
    width: 100%;
    margin-right: 8px;
    background-color: #F7FAFC;
    border-bottom: 1px solid #CAD1D7;  
    padding: 5px;
  
   
  
  }
  .ant-btn{
    border:none !important;
    background: none !important;
  }
  .ant-upload-span{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
 
  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
 .ant-upload-text-icon{
   display: none;
 }
 .upload-list-inline [class*='.ant-btn .ant-btn-text .ant-btn-sm .ant-btn-icon-only .ant-upload-list-item-card-actions-btn']{
  background-color: red !important;
 }
 /* #conditionalInputTotal_Number_of_Conduits{
   border: 1px solid black;
 } */
 thead tr th:first-child .ant-checkbox-inner::after {
  position: relative;
  color: black;
  border: none;
  width: 25px;
  height: 25px;
  background: transparent;
  transform: none;
  top: 0%;
  content: '-';
}
.ant-checkbox-wrapper .ant-checkbox-group-item{
  display: flex;
  
    align-items: center;
}